@import url('/node_modules/bootstrap/dist/css/bootstrap.css');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Prompt:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Orbitron&display=swap');

body {
  font-family: 'Kanit', sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  /* background-color: #F0F0F0; */
  background-image: url(images/background-lg.png);
  /* background-image: url(images/@kapook/background.jpeg); */
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom center;
}
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* TEXT
-----------------------------------*/
.font-16 {
  font-size: 1em;
}

.font-24 {
  font-size: 1.5em;
}

.font-32 {
  font-size: 32px;
}

.text-black {
  color: #000000;
}

.text-light-black {
  color: #080f18;
}

.text-red {
  color: #674900;
}

.text-orange {
  color: #149000;
}

.text-gold {
  color: #f1b625;
}

.text-white {
  color: #ffffff;
}

.text-light-gray {
  color: #727272;
}

/* BUTTON
-----------------------------------*/
.btn-black {
  background-color: #080f18;
}

.btn-eerie-black {
  background-color: #191c20;
}

.btn-grey {
  background-color: #e6e6e6;
}

.btn-gold {
  background-color: #f1b625;
}

.space-btn-inner {
  padding: 8px 24px;
}

.span-btn-width {
  padding: 8px 16px;
}

.radius-8 {
  border-radius: 8px;
}

.radius-16 {
  border-radius: 16px;
}

.radius-99 {
  border-radius: 99px;
}

.space-8 {
  gap: 8px;
}

/* BACKGROUNG
-----------------------------------*/
.bg-gold {
  background-color: #f1b625;
}

/* ELEMENT
-------------------------------------*/
.main-navigation {
  border-bottom: 2px solid #000000;
}

footer.bg-gold {
  border-top: 2px solid #000000;
}

ul.footer-nav-container {
  padding: 12px 8px;
  margin-bottom: 0;
}

ul.footer-nav-container > li {
  padding: 8px 16px;
  width: 19%;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

ul.footer-nav-container > li > a {
  text-decoration: none;
  color: #080f18;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

ul.footer-nav-container > li > a > i {
  width: 32px;
  height: 32px;
  display: block;
}

ul.footer-nav-container > li.active {
  background-color: #080f18;
  border-radius: 99px;
}

ul.footer-nav-container > li.active > a {
  color: #f1b625;
}

ul.footer-nav-container > li.footer-item-home > a > i {
  background-image: url(/src/images/icon_home.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

ul.footer-nav-container > li.footer-item-home.active > a > i {
  background-image: url(/src/images/icon_home_active.svg);
}

ul.footer-nav-container > li.footer-item-deposit > a > i {
  background-image: url(/src/images/icon_deposit.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

ul.footer-nav-container > li.footer-item-deposit.active > a > i {
  background-image: url(/src/images/icon_deposit_active.svg);
}

ul.footer-nav-container > li.footer-item-report-credit > a > i {
  background-image: url(/src/images/icon_report_credit.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

ul.footer-nav-container > li.footer-item-report-credit.active > a > i {
  background-image: url(/src/images/icon_report_credit_active.svg);
}

ul.footer-nav-container > li.footer-item-fgf > a > i {
  background-image: url(/src/images/icon_fgf.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

ul.footer-nav-container > li.footer-item-fgf.active > a > i {
  background-image: url(/src/images/icon_fgf_active.svg);
}

ul.footer-nav-container > li.footer-item-chat > a > i {
  background-image: url(/src/images/icon_chat.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

ul.footer-nav-container > li.footer-item-chat.active > a > i {
  background-image: url(/src/images/icon_chat_active.svg);
}

.container-w-border-shadow {
  background: #ffffff;
  box-shadow: 0px 4px 40px -1px rgb(159 159 159 / 24%);
  border-radius: 16px;
  padding: 1em;
}

/* Search Form
----------------------------------------*/
section.search-bar-container {
  background-color: #080f18;
}

div.search-form-container > div > span {
  background-color: #ffffff;
}

div.search-form-container > div > input.form-control {
  border: 0;
}

/* Lotto List
---------------------------------------*/
div.lotto-list-inner {
  height: 198px;
  box-sizing: border-box;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: cover;
  background-repeat: no-repeat;
}

div.lotto-list-wrapper > div {
  background-repeat: no-repeat;
  background-size: cover;
}

div.lotto-list-wrapper > a {
  text-decoration: none;
}

strong.lotto-title {
  color: #080f18;
  font-size: 1.2em;
}

span.lotto-details {
  color: #674900;
}

span.bet-enable {
  color: #ff0000;
}

span.bet-disable {
  color: #149000;
}

div.lotto-list-yeekeevip {
  background-image: url(/src/images/bg_lotto_yeekeeVIP.png);
}

div.lotto-list-crypto {
  background-image: url(/src/images/bg_lotto_crypto.png);
}

div.lotto-list-yeekee {
  background-image: url(/src/images/bg_lotto_yeekee.png);
}

div.lotto-list-corporate {
  background-image: url(/src/images/bg_lotto_corporate.png);
}

div.lotto-list-goverment {
  background-image: url(/src/images/bg_lotto_goverment.png);
}

div.lotto-list-baac {
  background-image: url(/src/images/bg_lotto_baac.png);
}

div.lotto-list-thaistock-evening {
  background-image: url(/src/images/bg_lotto_thaistock_evening.png);
}

div.lotto-list-thaistock-morning {
  background-image: url(/src/images/bg_lotto_thaistock_morning.png);
}

div.lotto-list-thaistock-afternoon {
  background-image: url(/src/images/bg_lotto_thaistock_afternoon.png);
}

div.lotto-list-thaistock-pm {
  background-image: url(/src/images/bg_lotto_thaistock_pm.png);
}

div.lotto-list-vietnam {
  background-image: url(/src/images/bg_lotto_vietnam.png);
}

div.lotto-list-hanoi {
  background-image: url(/src/images/bg_lotto_hanoi.png);
}

div.lotto-list-stock-korea {
  background-image: url(/src/images/bg_lotto_stock_korea.png);
}

div.lotto-list-stock-nikei {
  background-image: url(/src/images/bg_lotto_stock_nikei.png);
}

div.lotto-list-stock-nikei-afternoon {
  background-image: url(/src/images/bg_lotto_stock_nikei_afternoon.png);
}

div.lotto-list-stock-hungseng-afternoon {
  background-image: url(/src/images/bg_lotto_stock_hungseng_afternoon.png);
}

div.lotto-list-stock-hungseng-morning {
  background-image: url(/src/images/bg_lotto_stock_hungseng_morning.png);
}

div.lotto-list-stock-china-afternoon {
  background-image: url(/src/images/bg_lotto_stock_china_afternoon.png);
}

div.lotto-list-stock-china-morning {
  background-image: url(/src/images/bg_lotto_stock_china_morning.png);
}

div.lotto-list-stock-taiwan {
  background-image: url(/src/images/bg_lotto_stock_taiwan.png);
}

div.lotto-list-stock-singapore {
  background-image: url(/src/images/bg_lotto_stock_singapore.png);
}

div.lotto-list-stock-egypt {
  background-image: url(/src/images/bg_lotto_stock_egypt.png);
}

div.lotto-list-stock-german {
  background-image: url(/src/images/bg_lotto_stock_german.png);
}

div.lotto-list-stock-england {
  background-image: url(/src/images/bg_lotto_stock_england.png);
}

div.lotto-list-stock-russia {
  background-image: url(/src/images/bg_lotto_stock_russia.png);
}

div.lotto-list-stock-lao {
  background-image: url(/src/images/bg_lotto_stock_lao.png);
}

div.lotto-list-stock-india {
  background-image: url(/src/images/bg_lotto_stock_india.png);
}

div.lotto-list-stock-downjones {
  background-image: url(/src/images/bg_lotto_stock_downjones.png);
}

div.lotto-list-stock-malaysia {
  background-image: url(/src/images/bg_lotto_stock_malaysia.png);
}

div.lotto-list-stock-lao-star {
  background-image: url(/src/images/bg_lotto_stock_lao_star.png);
}

button.btn-ready-bet {
  width: 60%;
  border: none;
}

p.bet-countdown-detail {
  gap: 3px;
  width: 62%;
}

p.bet-countdown-detail > span::after {
  content: ' :';
}

p.bet-countdown-detail > span:last-child::after {
  content: '';
}

/* : LANDING
--------------------------------------*/
div.lotto-horizon-title > a {
  gap: 8px;
}

div.lotto-horizon-title > a > h2 {
  font-size: 1.5em;
}

/* Bet Dashboard
---------------------------------------*/
h2.bet-title-details-wrapper {
  background: linear-gradient(90deg, #f1b625 0%, rgba(241, 182, 37, 0) 100%);
  padding: 0.5em 1em;
}

h2.bet-title-details-wrapper {
  font-size: 1.5em;
  gap: 1em;
}

/* BET DETAIL
---------------------------------------*/
div.user-bet-list {
  border-top: 1px solid rgba(8, 15, 24, 16%);
  border-bottom: 1px solid rgba(8, 15, 24, 16%);
  padding: 8px 0;
}

h2.bet-title-details-wrapper > span.bet-title-details {
  font-weight: 300;
}

p.bet-time-countdown > span {
  font-size: 2em;
  font-weight: bold;
}

p.bet-time-countdown > span::after {
  content: ' : ';
}

p.bet-time-countdown > span:last-child::after {
  content: '';
}

div.bet-detail-dashboard-wrapper {
  background: #ffffff;
  box-shadow: 0px 4px 40px -1px rgba(159, 159, 159, 0.24);
  border-radius: 16px;
  padding: 1em;
}

/* Bet Details
--------------------------------------*/
div.bet-details {
  padding-top: 45px;
}

a.bet-by-doc {
  border: 1px solid rgba(8, 15, 24, 0.18);
  color: rgba(0, 0, 0, 48%);
  display: flex;
  align-items: center;
  gap: 8px;
}

p.no-bet {
  color: rgba(0, 0, 0, 48%);
}

a.bet-by-doc:hover {
  background-color: rgba(8, 15, 24, 0.18);
  color: #ffffff;
}

a.bet-by-doc > i.dollar-coin {
  height: 1.2em;
  width: 1.2em;
  display: block;
  background-image: url(/src/images/icon_number_doc.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

a.bet-by-doc:hover > i.dollar-coin {
  background-image: url(/src/images/icon_number_doc_active.svg);
}

div.user-bet-number > ul > li > a {
  color: rgba(0, 0, 0, 48%);
  border: 1px solid rgba(8, 15, 24, 0.18);
  padding: 0.5em 1em;
}

div.user-bet-number > ul > li > a.active,
div.user-bet-number > ul > li > a:hover {
  background-color: rgba(8, 15, 24, 0.18);
  color: #ffffff;
  border: 1px solid rgba(8, 15, 24, 0.18);
  font-weight: bold;
}

div.user-bet-number > p > span {
  font-weight: bold;
}

h3.bet-list-item-title {
  font-size: 1em;
  font-weight: bold;
}

/* Bet Table Detail */
table > tbody > tr > * {
  text-align: center;
}

table > tbody > tr > td.lotto-quantity > span {
  border: 1px solid rgba(8, 15, 24, 0.18);
  padding: 2px 2em;
}
/* NEW ADDEED */
table > tbody > tr > td.lotto-quantity > input {
  border: 1px solid rgba(8, 15, 24, 0.18);
  text-align: center;
  padding: 2px;
  width: 60px;
}

table > tbody > tr > td.lotto-rate {
  gap: 8px;
}

table > tbody > tr > td.lotto-rate > span:last-of-type {
  background-color: rgba(230, 230, 230, 1);
  font-size: 10px;
  padding: 2px 1.5em;
}

div.main-nav-bullet-container > ul,
div.bet-lotto-container > ul {
  gap: 1.5em;
}

div.main-nav-bullet-container > ul > li > button.nav-link,
div.bet-lotto-container > ul > li > button.nav-link {
  padding: 8px 56px;
  background-color: #e6e6e6;
  border-radius: 8px;
  color: #727272;
}

div.main-nav-bullet-container > ul > li > button.nav-link.active,
div.bet-lotto-container > ul > li > button.nav-link.active {
  background-color: rgba(241, 182, 37, 1);
  color: #080f18;
}

div.main-nav-bullet-container > ul > li > button,
div.bet-lotto-container > ul > li > button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

div.main-nav-bullet-container > ul > li > button > i.icon-bullet {
  width: 16px;
  height: 16px;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
}

div.main-nav-bullet-container > ul > li > button > i.icon-lotto {
  background-image: url(/src/images/icon_bullet_lotto.svg);
}

div.main-nav-bullet-container > ul > li > button.active > i.icon-lotto {
  background-image: url(/src/images/icon_bullet_lotto_active.svg);
}

div.main-nav-bullet-container > ul > li > button > i.icon-shoot-lotto {
  background-image: url(/src/images/icon_bullet_shoot_number.svg);
}

div.main-nav-bullet-container > ul > li > button.active > i.icon-shoot-lotto {
  background-image: url(/src/images/icon_bullet_shoot_number_active.svg);
}

div.main-nav-bullet-container > ul > li > button > i.icon-lotto-rule {
  background-image: url(/src/images/icon_rule.svg);
}

div.main-nav-bullet-container > ul > li > button.active > i.icon-lotto-rule {
  background-image: url(/src/images/icon_rule_active.svg);
}

div.main-tab-content-container {
  background: #ffffff;
  box-shadow: 0px 4px 40px -1px rgba(159, 159, 159, 0.24);
  border-radius: 16px;
  padding: 1.5em;
  overflow: hidden;
}

hr.divider-grey {
  color: rgba(8, 15, 24, 50%);
}

div.select-bet-lotto-container > nav > div.nav.nav-tabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-basis: 33%;
}

div.select-bet-lotto-container > nav > div > button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 33%;
  color: rgba(114, 114, 114, 1);
}

div.select-bet-lotto-container > nav > div > button.nav-link.active {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 3px solid #f1b625;
  color: #080f18;
  font-weight: 500;
}

div.select-bet-lotto-container > nav > div > button.nav-link:hover,
div.select-bet-lotto-container > nav > div > button.nav-link:focus {
  border-top: none;
  border-left: none;
  border-right: none;
}

div.search-lotto-number-container > div > div > div > div.form-outline {
  width: 90%;
}

div.search-lotto-number-container > div > div > div > div.form-outline > input {
  border-radius: 8px 0 0 8px;
  border-color: rgba(8, 15, 24, 0.16);
}

div.search-lotto-number-container > div > div > div.input-group > button {
  background-color: #f1b625;
  border-color: transparent;
}

button.reverse-number {
  width: 100%;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
}

button.reverse-number.active,
button.reverse-number:hover {
  background-color: #f1b625;
  color: #080f18;
  border-color: #f1b625;
}

.btn-outline-light-grey {
  border: 1px solid rgba(8, 15, 24, 0.18);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 3em;
}

div.select-hundred-number > div > ul,
div.selct-main-unit-number > div > ul {
  gap: 8px;
}

div.select-hundred-number > div > ul > li {
  width: 18%;
}

div.select-hundred-number > div > ul > li > a {
  color: rgba(0, 0, 0, 48%);
}

div.select-hundred-number > div > ul > li > a:hover,
div.select-hundred-number > div > ul > li > a.btn-outline-light-grey.active,
div.selct-main-unit-number > div > ul > li > a.btn-outline-light-grey:hover,
div.selct-main-unit-number > div > ul > li > a.btn-outline-light-grey.active {
  background-color: #f1b625;
  font-weight: bold;
  color: #000000;
}

div.select-lotto-number-container {
  position: relative;
}

div.overlay-paddle.active {
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: #d9d9d979;
}

div.selct-main-unit-number > div > ul > li {
  width: 8%;
}

div.selct-main-unit-number > div > ul > li > a.btn-outline-light-grey {
  padding: 2px 12px;
  color: rgba(0, 0, 0, 48%);
}

div.search-lottobox > input {
  text-align: center;
}

div.search-lottobox.active > input,
div.search-lottobox:hover > input {
  background-color: #f1b625;
  border-color: #f1b625;
  font-weight: 500;
}

div.search-lottobox.active > span,
div.search-lottobox:hover > span {
  border-color: #f1b625;
}

div.nav.nav-tabs > button:hover,
div.nav.nav-tabs > button.nav-link.active,
div.nav.nav-tabs > button.nav-link.active:focus-visible,
div.nav.nav-tabs > button.nav-link:focus-visible,
div.nav.nav-tabs > button.nav-link:focus {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  color: #080f18;
}

button.nav-link:focus-visible {
  border-top: 0;
  border-top-color: #ffffff;
  border-right: 0;
  border-left: 0;
}

/* BET 2 Numbers
------------------------------------------*/
div.select-1-number > div > ul {
  gap: 8px;
  flex-wrap: wrap;
}

div.select-1-number > div > ul > li.list-inline-item {
  width: 6%;
  margin-right: 2px;
}

div.select-1-number > div > ul > li.list-inline-item > a {
  padding: 2px 10px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  width: 100%;
  color: rgba(0, 0, 0, 48%);
}

div.select-1-number > div > ul > li.list-inline-item > a.active,
div.select-1-number > div > ul > li.list-inline-item > a:hover,
div.select-1-number > div > ul > li.list-inline-item > a:focus {
  background-color: #f1b625;
  font-weight: 500;
}

/* BET Running Number
---------------------------------------*/

div.select-runnig-number > div > ul > li.list-inline-item {
  width: 8%;
}

div.select-runnig-number > div > ul > li.list-inline-item > a {
  padding: 1em;
}

div.select-runnig-number > div > ul > li.list-inline-item > a {
  color: rgba(0, 0, 0, 48%);
}

div.select-runnig-number > div > ul > li.list-inline-item > a.active,
div.select-runnig-number > div > ul > li.list-inline-item > a:hover,
div.select-runnig-number > div > ul > li.list-inline-item > a:focus {
  background-color: #f1b625;
  font-weight: 500;
}

/* Bet Navigation
--------------------------------------*/
.btn-bet-navigation {
  background-color: #f1b625;
  color: #080f18;
  font-weight: 500;
}

.btn-bet-arrow-nav {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
}

.bet-arrow-left {
  background-image: url(/src/images/icon_left_arrow.svg);
}

.bet-arrow-right {
  background-image: url(/src/images/icon_right_arrow.svg);
}

div.bet-detail-dashboard-wrapper > div.bet-process-complete > button.w-100 {
  border: none !important;
}

/* Bet Rules
--------------------------------------*/
div.bet-rule-details-container {
  padding-top: 130px;
}

div.bet-detail-dashboard-wrapper
  > div.table-responsive
  > table
  > thead
  > tr
  > th:first-of-type,
div.bet-detail-dashboard-wrapper
  > div.table-responsive
  > table
  > tbody
  > tr
  > td:first-of-type {
  text-align: center;
}

div.bet-detail-dashboard-wrapper
  > div.table-responsive
  > table
  > thead
  > tr
  > th:last-of-type,
div.bet-detail-dashboard-wrapper
  > div.table-responsive
  > table
  > tbody
  > tr
  > td:last-of-type {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.text-align-end {
  text-align: end;
}

.text-align-center {
  text-align: center;
}

.text-align-justify {
  text-align: justify;
}

.text-align-start {
  text-align: start;
}

.text-align-inherit {
  text-align: inherit;
}

/* Bet Manual
--------------------------------------*/
ul.bet-manual-nav-container > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #e6e6e6;
  color: #727272;
}

ul.bet-manual-nav-container > li > a.active,
ul.bet-manual-nav-container > li > a:hover {
  background-color: #080f18;
  color: #f1b625;
  font-weight: 500;
}

div.caluculator-container > div > div > button {
  font-size: 2em;
  height: 52px;
  padding: 10px;
  align-items: center;
  font-family: 'Orbitron';
  font-style: normal;
  font-weight: 400;
  background-color: #080f18;
  color: #ffffff;
}

div.caluculator-container > div.row > div.col-4 > button.btn:hover {
  background-color: #f1b625;
  color: #080f18;
}

div.caluculator-container > div > div > button > img {
  width: 24;
  height: 24;
}

.pin-code {
  gap: 18px;
}

label.number-input {
  border: 1px solid rgba(8, 15, 24, 0.16);
  border-radius: 2px;
  padding: 1em 1.5em;
  font-size: 1.5em;
}

p.count-down-clock {
  font-weight: 500;
}

div.shoot-number-container
  div.caluculator-container
  > div.row
  > div.col-4
  > button {
  height: 72px;
}

/* Select Number From Poll
--------------------------------------*/
div.bet-poll-detail-container {
  background: #ffffff;
  box-shadow: 0px 4px 40px -1px rgba(159, 159, 159, 0.24);
  border-radius: 16px;
  padding: 1em;
}

div.bet-poll-detail-container > table > thead > tr > th:last-of-type {
  text-align: right;
}

div.bet-poll-detail-container > table > tbody > tr > td {
  padding: 8px 0;
}

div.bet-poll-detail-container > table > tbody > tr > td:first-child {
  gap: 8px;
  padding: 11px 0;
}

div.bet-poll-detail-container > table > tbody > tr > td > span {
  text-align: left;
}

div.bet-poll-detail-container > table > tbody > tr > td > span:first-of-type {
  width: 50%;
}

div.bet-poll-detail-container > table > tbody > tr > td > span:nth-child(2) {
  width: 10%;
}

div.bet-poll-detail-container > table > tbody > tr > td > span:last-of-type {
  width: 30%;
}

div.bet-poll-detail-container > table > tbody > tr > td:last-of-type {
  text-align: right;
}

.btn-select-number {
  border: 1px solid rgba(8, 15, 24, 0.16);
  border-radius: 8px;
  padding: 2px 24px;
  display: inline-block;
}

.btn-select-number:hover {
  background-color: #f1b625;
  color: #ffffff;
}

div.select-type-number-container > div > div > ul {
  gap: 8px;
}

div.select-type-number-container
  > div
  > div
  > ul
  > li.nav-item
  > button.nav-link {
  background-color: #e6e6e6;
  color: #727272;
  gap: 8px;
}

div.select-type-number-container
  > div
  > div
  > ul
  > li.nav-item
  > button.nav-link.active,
div.select-type-number-container > div > div > ul > li.nav-item > button:hover {
  background-color: #f1b625;
  color: #080f18;
}

div.select-type-number-container > div > div > ul > li.nav-item > button > i {
  width: 16px;
  height: 16px;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
}

div.select-type-number-container
  > div
  > div
  > ul
  > li.nav-item
  > button
  > i.icon-select-number-poll {
  background-image: url(/src/images/icon_select_number_poll.svg);
}

div.select-type-number-container
  > div
  > div
  > ul
  > li.nav-item
  > button.nav-link.active
  > i.icon-select-number-poll,
div.select-type-number-container
  > div
  > div
  > ul
  > li.nav-item
  > button:hover
  > i.icon-select-number-poll {
  background-image: url(/src/images/icon_select_number_poll_active.svg);
}

div.select-type-number-container
  > div
  > div
  > ul
  > li.nav-item
  > button
  > i.icon-browse-number-poll {
  background-image: url(/src/images/icon_browse_number_poll.svg);
}

div.select-type-number-container
  > div
  > div
  > ul
  > li.nav-item
  > button.nav-link.active
  > i.icon-browse-number-poll,
div.select-type-number-container
  > div
  > div
  > ul
  > li.nav-item
  > button.nav-link:hover
  > i.icon-browse-number-poll {
  background-image: url(/src/images/icon_browse_number_poll_active.svg);
}

div.pagination-container > div > div > nav > ul > li > a {
  border: none;
  color: rgba(8, 15, 24, 0.48);
}

div.pagination-container > div > div > nav > ul > li > a.page-link.active,
div.pagination-container > div > div > nav > ul > li > a.page-link:hover {
  background-color: #f1b625;
  color: #080f18;
  cursor: pointer;
}

div.pagination-container
  > div
  > div
  > nav
  > ul
  > li:first-of-type
  > a.page-link {
  background-color: rgba(238, 238, 238, 1);
  border-radius: 99px;
  margin-right: 3em;
  padding: 8px 24px;
}

div.pagination-container
  > div
  > div
  > nav
  > ul
  > li:last-of-type
  > a.page-link {
  background-color: #f1b625;
  border-radius: 99px;
  margin-left: 3em;
  color: #080f18;
  padding: 8px 24px;
}

/* BET POLL PAGE
--------------------------------------*/
ul.bet-poll-navigation {
  gap: 0rem;
}
ul.bet-poll-navigation>li.nav-item {
  flex-basis: unset !important;
  @media (max-width: 425px) {
      margin-bottom: 0.5rem;
    }
}
ul.bet-poll-navigation > li.nav-item > button {
  background-color: #e6e6e6;
  color: #727272;
  gap: 8px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

ul.bet-poll-navigation > li.nav-item > button.active,
ul.bet-poll-navigation > li.nav-item:hover > button {
  background-color: #f1b625;
  color: #080f18;
}

ul.bet-poll-navigation > li.nav-item > button > i {
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: cover;
}

ul.bet-poll-navigation > li.nav-item > button > i.icon-bet-poll-recent {
  background-image: url(/src/images/icon_bet_poll_recent.svg);
}

ul.bet-poll-navigation > li.nav-item > button.active > i.icon-bet-poll-recent,
ul.bet-poll-navigation > li.nav-item > button:hover > i.icon-bet-poll-recent {
  background-image: url(/src/images/icon_bet_poll_recent_active.svg);
}

ul.bet-poll-navigation > li.nav-item > button > i.icon-bet-poll-result {
  background-image: url(/src/images/icon_bet_poll_result.svg);
}

ul.bet-poll-navigation > li.nav-item > button.active > i.icon-bet-poll-result,
ul.bet-poll-navigation > li.nav-item > button:hover > i.icon-bet-poll-result {
  background-image: url(/src/images/icon_bet_poll_result_active.svg);
}

ul.bet-poll-navigation > li.nav-item > button > i.icon-bet-poll-summary {
  background-image: url(/src/images/icon_bet_poll_summary.svg);
}

ul.bet-poll-navigation > li.nav-item > button.active > i.icon-bet-poll-summary,
ul.bet-poll-navigation > li.nav-item > button:hover > i.icon-bet-poll-summary {
  background-image: url(/src/images/icon_bet_poll_summary_active.svg);
}

div.bet-user-poll-detail-container > div > div > p > strong,
div.bet-user-poll-detail-container > div > div > p > a > img {
  margin-right: 8px;
}

div.bet-user-poll-detail-container > div > div > p > strong::after {
  content: ' : ';
}

div.bet-user-poll-detail-container > div > div > p > span {
  color: #f1b625;
}

div.bet-user-poll-detail-container > div > div > p > span.btn-black,
div.bet-user-poll-detail-container > div > div > p > span.btn-gold {
  padding: 2px 16px;
  font-weight: 500;
}

div#pills-tabContent
  > div#pills-bet-poll-summary
  > div.position-relative
  > h3.position-absolute.start-50.translate-middle {
  font-size: 18px;
  background-color: #f1b625;
  border-radius: 99px;
  padding: 4px 32px;
}

div#pills-bet-poll-summary > div.position-relative > div > div > div > h4 {
  color: #080f18;
}

div#pills-bet-poll-summary > div.position-relative > div > div > div > p {
  font-weight: bold;
  font-size: 1.5em;
  color: #f1b625;
}
/* BTN : Back
-------------------------------------------*/
div.btn-back-container > a.btn {
  border-radius: 8px;
  border: 1px solid #f1b625;
  padding: 0.5em 1em;
  margin-right: 1em;
}

div.btn-back-container > a.btn:last-child {
  margin-right: 0;
}

div.btn-back-container > a.btn-back-home {
  background-color: #f1b625;
}

/* Loading
-------------------------------------------*/
.loader-container {
  min-height: 80vh;
}

.loader {
  width: 200px;
  height: 200px;
  border: 5px solid #f1b625;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Responsive
--------------------------------------*/
@media (max-width: 575.98px) {
  ul.footer-nav-container {
    padding: 4px;
  }

  ul.footer-nav-container > li {
    width: 18%;
  }

  ul.footer-nav-container > li.active {
    border-radius: 8px;
  }

  ul.footer-nav-container > li > a {
    font-size: 10px;
    text-align: center;
  }

  ul.footer-nav-container > li > a {
    flex-direction: column;
  }

  section.search-bar-container > div > div > p {
    font-weight: bold;
    color: #080f18;
  }

  section.search-bar-container {
    background-color: transparent;
  }

  div.search-form-container > div > span {
    background-color: #080f18;
  }

  div.search-form-container > div > input.form-control {
    border-top: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
  }

  section.lotto-list-section-container > div > h2 {
    font-size: 1.2em;
    font-weight: 600;
    font-style: italic;
  }

  div.lotto-list-inner {
    height: 106px;
    padding: 0.5em;
  }

  strong.lotto-title {
    font-size: 14px;
  }

  span.lotto-details,
  span.bet-enable,
  span.bet-disable {
    font-size: 12px;
  }

  div.lotto-list-inner > p {
    line-height: 1em;
  }

  button.btn-ready-bet,
  p.bet-countdown-detail {
    width: 100%;
    font-size: 14px;
    padding: 2px;
  }

  /*Footer*/
  div.main-container {
    padding-bottom: 100px;
  }

  footer.bg-gold {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 999;
  }

  ul.footer-nav-container > li {
    padding: 0;
  }

  ul.footer-nav-container > li > a {
    gap: 0;
  }

  h2.bet-title-details-wrapper {
    flex-direction: column;
    gap: 0;
  }

  div.main-nav-bullet-container > ul,
  div.bet-lotto-container > ul {
    gap: 8px;
    justify-content: center;
  }

  div.main-nav-bullet-container > ul > li > button.nav-link,
  div.bet-lotto-container > ul > li > button.nav-link {
    padding: 4px 16px;
  }

  div.main-tab-content-container {
    padding: 1em;
  }

  div.search-lotto-number-container > div > div > div > div.form-outline {
    width: 84%;
  }

  .btn-outline-light-grey {
    padding: 2px;
  }

  div.select-hundred-number > div > ul {
    gap: 7px;
    justify-content: start;
  }

  div.select-hundred-number > div > ul > li.list-inline-item {
    width: 18%;
    margin-bottom: 8px;
    margin-right: 0;
  }

  div.selct-main-unit-number > div > ul {
    gap: 14px;
  }

  div.selct-main-unit-number > div > ul > li > a.btn-outline-light-grey {
    padding: 2px 20px;
  }

  div.selct-main-unit-number > div > ul > li {
    width: 10%;
  }

  div.select-1-number > div > ul > li.list-inline-item > a {
    padding: 2px 12px;
  }

  div.select-runnig-number > div > ul {
    gap: 2px;
  }

  div.select-runnig-number > div > ul > li.list-inline-item {
    width: 9%;
    margin-right: 0;
  }

  div.select-runnig-number > div > ul > li.list-inline-item > a {
    padding: 0.5em;
    border-radius: 4px;
  }

  div.bet-details,
  div.bet-rule-details-container {
    padding-top: 0px;
  }

  div.shoot-number-container div.pin-code {
    gap: 5px;
  }

  div.shoot-number-container label.number-input {
    padding: 0.5em 0.7em;
  }

  div.bet-poll-detail-container > table > tbody > tr > td {
    align-content: flex-start;
    justify-content: flex-start;
  }

  div.bet-poll-detail-container > table > tbody > tr > td > span.bet-poll-title,
  div.bet-poll-detail-container
    > table
    > tbody
    > tr
    > td
    > span.bet-poll-detail,
  div.bet-poll-detail-container
    > table
    > tbody
    > tr
    > td
    > span.bet-poll-date-time {
    width: 100%;
    text-align: left;
    display: block;
  }

  div.bet-poll-detail-container
    > table
    > tbody
    > tr
    > td
    > span.bet-poll-detail {
    width: 50%;
    text-align: left;
  }

  div.pagination-container
    > div
    > div
    > nav
    > ul
    > li:first-of-type
    > a.page-link {
    margin-right: 0.5em;
  }

  div.pagination-container
    > div
    > div
    > nav
    > ul
    > li:last-of-type
    > a.page-link {
    margin-left: 0.5em;
  }

  ul.bet-poll-navigation > li.nav-item > button {
    padding: 0.2em;
    gap: 3px;
    font-size: 12px;
  }

  div.container-w-border-shadow {
    padding: 1.5em 1em;
  }

  div#pills-bet-poll-summary > div.position-relative > div > div > div {
    padding: 1em;
  }

  div#pills-bet-poll-summary
    > div.position-relative
    > div
    > div
    > div:first-child {
    border-right: 1px solid #727272;
    border-bottom: 1px solid #727272;
  }

  div#pills-bet-poll-summary
    > div.position-relative
    > div
    > div
    > div:nth-child(2) {
    border-bottom: 1px solid #727272;
  }

  div#pills-bet-poll-summary
    > div.position-relative
    > div
    > div
    > div:nth-child(3) {
    border-right: 1px solid #727272;
  }

  div#pills-bet-poll-summary > div.position-relative > h3 {
    width: 75%;
  }
}
.contact-form-container {
  background-color: rgba(255, 255, 255, 48%);
  /* backdrop-filter: blur(10px); */
  border-radius: 0 1em 1em 0;
}

.contact-detail-main-container {
  box-shadow: 0px 4px 40px -1px rgba(159, 159, 159, 0.24);
}

div.contact-form-container form label {
  font-weight: bold;
}

button.btn-send-data {
  background-color: #F1B625;
  color: #080F18;
  border: 0;
}

button.btn-send-data:hover {
  background-color: #080F18;
  color: #F1B625;
}

.contact-detail-container {
  background-color: #F1B625;
  border-radius: 1em 0 0 1em;
}

.contact-details-wrapper {
  background-color: #080F18;
  border-radius: 8px;
}

.contact-details-wrapper a {
  text-decoration: none;
  color: #F1B625;
}

.contact-details-wrapper h3 {
  color: #F1B625;
  font-size: 1.2em;
}

/* Responsive
--------------------------------------*/
@media (max-width: 575.98px) {
  button.btn-send-data {
    width: 100%;
  }

  .contact-detail-container {
    border-radius: 0;
  }
}

/* New Footer
--------------------------------------*/
.footer-white-container {
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  z-index: 9999;
  width: 100%;
  padding: 2px 0 0 0;
}

.footer-list-items-title {
  font-size: 10px;
  color: #9F9F9F;
}

a:hover .footer-list-items-title,
a.active .footer-list-items-title {
  color: #F1B625;
}

a.active div.footer-icon-list-items-wrapper::before,
a:hover div.footer-icon-list-items-wrapper::before {
  content: " ";
  border-radius: 99px;
  border: 1px solid #F1B625;
  width: 2%;
  background: #F1B625;
  position: absolute;
  top: 0;
  z-index: 99;
}

@media (max-width: 575.98px) {

  a.active div.footer-icon-list-items-wrapper::before,
  a:hover div.footer-icon-list-items-wrapper::before {
    width: 10%;
  }
}

@media (min-width: 768px) {

  a.active div.footer-icon-list-items-wrapper::before,
  a:hover div.footer-icon-list-items-wrapper::before {
    width: 5%;
  }
}

i.footer-icon {
  width: 1.2em;
  height: 1.2em;
  margin-top: 4px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: grayscale(50);
}

i.footer-icon:hover,
a.active i.footer-icon {
  filter: grayscale(0);
}

i#footer-icon-home {
  background-image: url(/src/images/icon-footer-home-normal.svg);
}

i#footer-icon-bet-list {
  background-image: url(/src/images/icon-footer-bet-list.svg);
}

i#footer-icon-analysis {
  background-image: url(/src/images/icon-footer-analysis.svg);
}

i#footer-icon-make-lotto-set {
  background-image: url(/src/images/icon-footer-make-lotto-set.svg);
}
i#footer-icon-contact-us {
  background-image: url(/src/images/icon_chat_active.svg);
}

i#footer-icon-home:hover {
  filter: grayscale(0);
}

/* Header
-------------------------------------------*/
.bg-white {
  background-color: #ffffff;
}

.main-navigation {
  border-color: #F1B625;
}

.user-credit-wrapper {
  background-color: #E4E6EB;
  border-radius: 99px;
  padding: 1px 8px;
}

.user-name {
  font-size: 12px;
}

.user-credit-container {
  font-size: 10px;
}

.user-credit {
  color: #F1B625;
  font-weight: bold;
}

/* Lotto Bet List
-------------------------------------------*/
.lotto-bet-list-container {
  background: #ffffff;
  border-radius: 1em;
  box-shadow: 0px 4px 40px -1px rgba(159, 159, 159, 0.24);
}

.lotto-bet-list-container h3 {
  font-size: 18px;
  color: #F1B625;
}

table.table-bet-lotto-list>thead>tr>th {
  font-size: 14px;
  font-weight: 400;
  color: #67707B;
}

table.table-bet-lotto-list>thead>tr>th:nth-child(2),
table.table-bet-lotto-list>tbody>tr>td:nth-child(2) {
  text-align: center;
}

table.table-bet-lotto-list>thead>tr>th:last-child,
table.table-bet-lotto-list>tbody>tr>td:last-of-type {
  text-align: right;
}

table.table-bet-lotto-list>tbody>tr>td:first-of-type {
  text-align: left;
  color: #080F18;
}

table.table-bet-lotto-list>tbody>tr>td:nth-child(2) {
  color: #080F18;
}

.bet-number {
  font-size: 18px;
}

.bet-number::after {
  content: " ";
  font-size: 0.5em;
}

.bet-number-amount {
  font-size: 15px;
  color: #F1B625;
}

.bet-status {
  border-radius: 110px;
  background: #080F18;
  color: #F1B625;
  padding: 2px 10px;
  font-size: 14px;
}

.main-container {
  padding-top: 16px;
  padding-bottom: 60px;
}

.bet-list-number {
  border-radius: 8px;
  background: linear-gradient(90deg, #F1B625 0%, rgba(241, 182, 37, 0.00) 100%);
}

.bet-number-list-detail p {
  margin-bottom: 0.5em;
}

.bet-number-list-detail p>strong {
  font-style: italic;
  font-weight: 600;
}

.bet-number-list-detail p>strong::after {
  content: " : ";
}

.bet-number-list-detail p>span {
  color: #F1B625;
}

/* 10/09/2003 : Lotto Result 
----------------------------------------*/
ul.nav-result-tab-container>li.nav-item>button {
  background-color: #e6e6e6;
  color: #727272;
  width: 98% !important;
}

@media (max-width: 575.98px) {
  ul.nav-result-tab-container>li.nav-item>button {
    font-size: 0.8em;
  }
}

ul.nav-result-tab-container>li.nav-item>button.active,
ul.nav-result-tab-container>li.nav-item>button:hover {
  background-color: #F1B625;
  color: #080F18;
}

ul.nav-result-tab-container>li.nav-item>button>i {
  margin-right: 4px;
}

.lotto-result-card-container {
  background: linear-gradient(180deg, rgba(202, 202, 202, 0.08) 0%, rgba(255, 255, 255, 0.20) 23.44%, rgba(255, 255, 255, 0.20) 81.77%, rgba(202, 202, 202, 0.08) 100%), #FFF;
  border-radius: 1em;
  box-shadow: 0px 4px 40px -1px rgba(159, 159, 159, 0.24);
}

.lotto-result-header {
  color: #F1B625;
  font-size: 1em;
}

.lotto-result-mini-flag {
  width: 1.2em;
  height: auto;
  margin-right: 0.2em;
  border: 4px;
}

.lotto-result-date {
  color: #67707B;
  font-weight: 400;
}

.lotto-result-summary-header {
  color: #67707B;
  font-size: 1em;
}

.lotto-result-summary-number {
  color: #F1B625;
  font-size: 1.5em;
}

.lotto-result-table-title {
  color: #67707B;
  font-weight: 400;
  font-size: 1em;
}

@media (max-width: 575.98px) {
  .lotto-result-table-title {
    font-size: 0.7em;
  }
}

.table-row {
  border-bottom: 1px solid rgb(222, 226, 230);
  padding-bottom: 0.2em;
  padding-top: 0.2em;
}

.table-row>.col-6>img {
  width: 1em;
  margin-right: 0.4em;
}

.lotto-result-status {
  font-weight: 500;
}

div.datepicker-container>div>button {
  border-color: rgb(222, 226, 230);
}

input#datepicker {
  border-color: rgb(222, 226, 230);
}

.yekee-result-status-round {
  border-radius: 8px;
  background: linear-gradient(90deg, #F1B625 0%, rgba(241, 182, 37, 0.00) 100%);
}

.yeekee-result-number-summary-number {
  color: #F1B625;
  font-size: 1.5em;
}

.yeekee-result-player-lists {
  border-bottom: 1px solid rgb(222, 226, 230);
  padding-bottom: 0.4em;
  padding-top: 0.2em;
}

.badge-yeekee-player-list {
  background-color: #e6e6e6;
  border-radius: 4px;
  font-size: 10px;
  padding: 2px 10px;
}

.badge-winning {
  background-color: #F1B625;
}

ul.pagination>li.page-item>a.page-link {
  color: #080F18;
}

ul.pagination>li.page-item.disable>a.page-link {
  color: #e6e6e6;
}

ul.pagination>li.page-item.active>a.page-link,
ul.pagination>li.page-item>a.page-link:hover {
  background-color: #F1B625;
  border-color: #F1B625;
}

/* Bet Poll Loading : 12/09/2023
----------------------------------*/
.loader-data-container>.loader-container {
  min-height: 10vh !important;
}

.loading-data-wrapper>.loader {
  width: 80px;
  height: 80px;
}

/* 10/09/2003 : Lotto Result 
----------------------------------------*/
ul.nav-result-tab-container>li.nav-item>button {
  background-color: #e6e6e6;
  color: #727272;
  width: 98% !important;
}

@media (max-width: 575.98px) {
  ul.nav-result-tab-container>li.nav-item>button {
    font-size: 0.8em;
  }
}

ul.nav-result-tab-container>li.nav-item>button.active,
ul.nav-result-tab-container>li.nav-item>button:hover {
  background-color: #F1B625;
  color: #080F18;
}

ul.nav-result-tab-container>li.nav-item>button>i {
  margin-right: 4px;
}

.lotto-result-card-container {
  background: linear-gradient(180deg, rgba(202, 202, 202, 0.08) 0%, rgba(255, 255, 255, 0.20) 23.44%, rgba(255, 255, 255, 0.20) 81.77%, rgba(202, 202, 202, 0.08) 100%), #FFF;
  border-radius: 1em;
  box-shadow: 0px 4px 40px -1px rgba(159, 159, 159, 0.24);
}

.lotto-result-header {
  color: #F1B625;
  font-size: 1em;
}

.lotto-result-mini-flag {
  width: 1.2em;
  height: auto;
  margin-right: 0.2em;
  border: 4px;
}

.lotto-result-date {
  color: #67707B;
  font-weight: 400;
}

.lotto-result-summary-header {
  color: #67707B;
  font-size: 1em;
}

.lotto-result-summary-number {
  color: #F1B625;
  font-size: 1.5em;
}

.lotto-result-table-title {
  color: #67707B;
  font-weight: 400;
  font-size: 1em;
}

@media (max-width: 575.98px) {
  .lotto-result-table-title {
    font-size: 0.7em;
  }
}

.table-row {
  border-bottom: 1px solid rgb(222, 226, 230);
  padding-bottom: 0.2em;
  padding-top: 0.2em;
}

.table-row>.col-6>img {
  width: 1em;
  margin-right: 0.4em;
}

.lotto-result-status {
  font-weight: 500;
}

div.datepicker-container>div>button {
  border-color: rgb(222, 226, 230);
}

input#datepicker {
  border-color: rgb(222, 226, 230);
}

.yekee-result-status-round {
  border-radius: 8px;
  background: linear-gradient(90deg, #F1B625 0%, rgba(241, 182, 37, 0.00) 100%);
}

.yeekee-result-number-summary-number {
  color: #F1B625;
  font-size: 1.5em;
}

.yeekee-result-player-lists {
  border-bottom: 1px solid rgb(222, 226, 230);
  padding-bottom: 0.4em;
  padding-top: 0.2em;
}

.badge-yeekee-player-list {
  background-color: #e6e6e6;
  border-radius: 4px;
  font-size: 10px;
  padding: 2px 10px;
}

.badge-winning {
  background-color: #F1B625;
}

ul.pagination>li.page-item>a.page-link {
  color: #080F18;
}

ul.pagination>li.page-item.disable>a.page-link {
  color: #e6e6e6;
}

ul.pagination>li.page-item.active>a.page-link,
ul.pagination>li.page-item>a.page-link:hover {
  background-color: #F1B625;
  border-color: #F1B625;
}

a {
  cursor: pointer !important;
}

/* Loading Screen : 17/09/2023
----------------------------------*/
body.loading-page-container {
  background-attachment: scroll;
  background-image: none;
  background-color: #ffffff;
}

.header-loading {
  font-size: 6em;
  background: -webkit-linear-gradient(#b48132, #fae0af, #b4812e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sub-header-loading {
  font-size: 2.5rem;
}

.loading-bg {
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.loading-bg-mobile {
  background-image: url(images/bg_loading_desktop.png);
}

.lottie-player-wrapper {
  position: relative;
  top: -125px;
}

/* Mobile */
@media (max-width: 575.98px) {
  .loading-bg-mobile {
    background-image: url(images/bg_loading_mobile.png);
  }

  .header-loading {
    font-size: 2.5em;
  }

  .sub-header-loading {
    font-size: 1.5rem;
  }
}

/* iPad */
@media (min-width: 768px) {
  .header-loading {
    font-size: 5em;
  }

  .lottie-player-wrapper {
    position: relative;
    top: -230px;
  }
}

/* Desktop */
@media (min-width: 992px) {
  .lottie-player-wrapper {
    position: relative;
    top: -200px;
  }
}

/* Language Selector : 20/09/2023
----------------------------------*/
.language-selector>select#language {
  border: 0;
  text-transform: uppercase;
  font-size: 12px;
  border-radius: 99px;
  background-color: #F1B625;
  color: #080F18;
  padding: 0.3em 0.5em;
}

.language-selector>select#language>option[value="en"] {
  background-image: url(/src/images/banner.png);
  background-position: left center;
  background-repeat: no-repeat;
  content: " ";
  width: 12px;
  height: 12px;
  display: inline-block;
}

/* Annoucement : 11/10/2023
----------------------------------*/
.annoucement-container {
  border-radius: 8px;
  background: linear-gradient(180deg, #F2B726 0%, #AD7E0A 100%);
}

.marquee-container {
  border-radius: 4px;
  background: #121212;
}

.marquee-text {
  color: #F2B726;
}

.annoucement-icon {
  transform: translateY(-40px);
}

.annoucement-icon>img {
  width: 90px;
  height: auto;
}

.result_table {
      max-height: 500px;
}

@media (max-width: 575.98px) {
  .annoucement-icon>img {
    width: 80px;
    height: auto;
  }

  .annoucement-icon {
    transform: translate(-18px, -32px);

  }


}

/* Date Picker : 2023 / 10 /21
--------------------------------------------*/
div.datepicker-container>div.input-group.mb-3>div.react-date-picker {
  background-color: #080F18
}

div.datepicker-container>div.input-group.mb-3>div.react-date-picker>div.react-date-picker__wrapper {
  border-color: #F1B625;
  border-radius: 4px;
  color: #F1B625;
}

div.datepicker-container>div.input-group.mb-3>div.react-date-picker>div.react-date-picker__wrapper>button>svg {
  fill: white;
  background-color: #F1B625;
  border-radius: 4px;
}

div.datepicker-container>div.input-group.mb-3>button.btn.btn-outline-secondary.border-left-0 {
  background-color: #F1B625;
  border-left: 0;
}

div.datepicker-container>div.input-group.mb-3>button.btn.btn-outline-secondary.border-left-0>i {
  color: #080F18;
}

div.datepicker-container>div.input-group.mb-3>div.react-date-picker>span>div.react-date-picker__calendar.react-date-picker__calendar--open>div.react-calendar>div.react-calendar__viewContainer>div.react-calendar__month-view>div>div>div.react-calendar__month-view__weekdays {
  background-color: #080F18;
}

div.datepicker-container>div.input-group.mb-3>div.react-date-picker>span>div.react-date-picker__calendar.react-date-picker__calendar--open>div.react-calendar>div.react-calendar__viewContainer>div.react-calendar__month-view>div {
  margin-top: 0.5em;
}

div.datepicker-container>div.input-group.mb-3>div.react-date-picker>span>div.react-date-picker__calendar.react-date-picker__calendar--open>div.react-calendar>div.react-calendar__viewContainer>div.react-calendar__month-view>div>div>div.react-calendar__month-view__weekdays>div.react-calendar__month-view__weekdays__weekday {
  color: #F1B625;
  text-align: center;
  padding: 0.5em;
}

div.datepicker-container>div.input-group.mb-3>div.react-date-picker>span>div.react-date-picker__calendar.react-date-picker__calendar--open>div.react-calendar>div.react-calendar__viewContainer>div.react-calendar__month-view>div>div>div.react-calendar__month-view__weekdays {
  border-radius: 4px 4px 0 0;
}

div.react-calendar__viewContainer>div.react-calendar__month-view>div>div>div.react-calendar__month-view__days>button.react-calendar__tile.react-calendar__month-view__days__day {
  background-color: #F1B625;
  color: #080F18;
  border: 1px solid #080F18;
}


div.react-calendar__viewContainer>div.react-calendar__month-view>div>div>div.react-calendar__month-view__days>button.react-calendar__tile.react-calendar__month-view__days__day:hover {
  background-color: #080F18;
  color: #F1B625;
}

div.react-calendar__viewContainer>div.react-calendar__month-view>div>div>div.react-calendar__month-view__days>button.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active {
  background-color: #080F18;
  color: #F1B625;
}

div.datepicker-container>div.input-group.mb-3>div.react-date-picker>span>div.react-date-picker__calendar.react-date-picker__calendar--open>div.react-calendar>div.react-calendar__navigation {
  margin-top: 0.5em;
}

div.datepicker-container>div.input-group.mb-3>div.react-date-picker>span>div.react-date-picker__calendar.react-date-picker__calendar--open>div.react-calendar>div.react-calendar__navigation>button {
  border: 0;
  padding: 0.5em;
  background-color: #080F18;
  color: #F1B625;
}

div.datepicker-container>div.input-group.mb-3>div.react-date-picker>span>div.react-date-picker__calendar.react-date-picker__calendar--open>div.react-calendar>div.react-calendar__navigation>button.react-calendar__navigation__prev2-button {
  border-radius: 4px 0 0 4px;
}

div.datepicker-container>div.input-group.mb-3>div.react-date-picker>span>div.react-date-picker__calendar.react-date-picker__calendar--open>div.react-calendar>div.react-calendar__navigation>button.react-calendar__navigation__next2-button {
  border-radius: 0px 4px 4px 0;
}

/* 2023/12/07 Edit Bet Poll Status
---------------------------------------*/
.lotto-win::after {
  content: ' ';
  display: inline-block;
  white-space: pre;
  font-size: 0;
  background-image: url(/src/images/Celebrate.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 16px;
  height: auto;
  transform: translate(4px, -2px)
}



span.lotto-win {
  /* font-weight: 500; */
  color: #ffffff !important;
  background-color: #00AF07;
  padding: 1px 16px;
  border-radius: 99px;
}

.lotto-lost {
  font-size: 14px;
  color: #ffffff !important;
  background-color: #FF0E00;
  padding: 1px 16px;
  border-radius: 99px;
  margin-right: 8px;
}

.lotto-win,
.lotto-lost {
  min-width: 50px;
  /* Adjust as needed */
  font-size: 0.9rem;
  /* Slightly smaller on smaller screens */
}

@media (max-width: 576px) {

  .lotto-win,
  .lotto-lost {
    font-size: 0.8rem;
    /* Even smaller for extra small screens */
  }
}

@media (max-width: 768px) {

  /* Adjust this breakpoint as needed */
  .ReactModal__Content {
    width: 95% !important; 
    height: auto !important;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%) !important;
    maxHeight: '90vh', 
  }
}